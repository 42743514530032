import Vue from "vue";
import App from "./App.vue";
import { store } from "./store/plugin";
import vuetify from "./plugins/vuetify";
import router from "./router";
import moment from "moment";

import "animate.css";
import "./styles/variables1.scss";
import VueClipboard from "vue-clipboard2";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.use(VueClipboard);


Vue.prototype.$moment = moment;
Vue.prototype.$companyid = "1"; //1:www.newgame168, 2:www.nicegame188.com, 3:www.infinity.com, 4:new interface
Vue.prototype.$companyname = "新一代"; //1:"新一代", 2:"我們", 3:"無限", 4: new "新一代"
//+Vue.version
Vue.directive("lazy-load", {
  bind(el, binding) {
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 进入视窗，添加加载动画
          console.log(binding.value);
          entry.target.classList.add("animate__animated", binding.value);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(el);
  },
});

import MySnackbar from "@/components/MySnackbar.vue";

import LoadingFull from "@/components/LoadingFull.vue";
Vue.component("MySnackbar", MySnackbar);
Vue.component("LoadingFull", LoadingFull);

Vue.config.productionTip = false;

Vue.mixin({
  beforeCreate() {
    this.$store = store;
  },
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
