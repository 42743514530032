import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("@/views/layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "MainIndex",
        component: () => import("@/views/pages/index.vue"),
      },
      {
        path: "/goods",
        name: "Goods",
        component: () => import("@/views/pages/goods.vue"),
        meta: {},
      },
      {
        path: "/goods2",
        name: "Goods2",
        component: () => import("@/views/pages/goods2.vue"),
        meta: {},
      },
      {
        path: "/games",
        name: "Games",
        component: () => import("@/views/pages/games.vue"),
        meta: {},
      },

      {
        path: "/Article",
        name: "Article",
        component: () => import("@/views/pages/article.vue"),
        meta: {},
      },
    ],
  },
  {
    path: "",
    component: () => import("@/views/layouts/MainLayoutBlank.vue"),
    children: [
      {
        path: "/gameredirect",
        name: "GameRedirect ",
        component: () => import("@/views/pages/gameredirect.vue"),
        meta: {},
      },
    ],
  },

  {
    path: "",
    component: () => import("@/views/layouts/MainLayoutThree.vue"),
    children: [
      {
        path: "/member",
        name: "Member",
        component: () => import("@/views/pages/member.vue"),
        meta: {},
      },
      {
        path: "/deposit",
        name: "Deposit",
        component: () => import("@/views/pages/deposit.vue"),
        meta: {},
      },
      {
        path: "/withdraw",
        name: "Withdraw",
        component: () => import("@/views/pages/withdraw.vue"),
        meta: {},
      },
      {
        path: "/rebate",
        name: "Rebate",
        component: () => import("@/views/pages/rebate.vue"),
        meta: {},
      },
      {
        path: "/activity",
        name: "Activity",
        component: () => import("@/views/pages/activity.vue"),
        meta: {},
      },
      {
        path: "/memberrecord",
        name: "MemberRecord",
        component: () => import("@/views/pages/memberrecord.vue"),
        meta: {},
      },
      {
        path: "/memberbank",
        name: "MemberBank",
        component: () => import("@/views/pages/memberbank.vue"),
        meta: {},
      },
      {
        path: "/memberpromo",
        name: "MemberPromo",
        component: () => import("@/views/pages/memberpromo.vue"),
        meta: {},
      },
      {
        path: "/membersetting",
        name: "MemberSetting",
        component: () => import("@/views/pages/membersetting.vue"),
        meta: {},
      },
      {
        path: "/gamebalance",
        name: "GameBalance",
        component: () => import("@/views/pages/gamebalance.vue"),
        meta: {},
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/pages/login.vue"),
        meta: {},
      },

      {
        path: "/signup",
        name: "Signup",
        component: () => import("@/views/pages/signup.vue"),
        meta: {},
      },
    ],
  },

  {
    path: "",
    component: () => import("@/views/layouts/MainLayoutTwo.vue"),
    children: [
      // {
      //   path: "/member",
      //   name: "Member",
      //   component: () => import("@/views/pages/member.vue"),
      //   meta: {},
      // },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.query.ref && from.query.ref) {
    to.query.ref = from.query.ref;
    next({ name: to.name, query: to.query });
  } else {
    next();
  }
});

export default router;
