<template>
  <v-app id="app">
    <!-- v-bind="currentProperties"  -->
    <router-view
      @eventname="playmusic"
      @eventname2="closedialog"
      v-slot="{ Component, route }"
    >
      <component :is="Component" :key="route.path" />
    </router-view>
    <!-- <div>
      <v-dialog v-model="dialog" persistent width="auto">
        <v-card>
          <v-card-title class="text-h5"> 系統公告 </v-card-title>
          <v-card-text>重要：實名登記開戶事項 </v-card-text>
          <v-card-text
            >開戶/註冊帳戶「必須」使用真實英文全名和正確電話號碼登記，登記名字必須和綁定銀行名字一致；否則會不能正常上落分。
            如使用虛假姓名登記和不正確電話號碼導致發生各方面問題官方一概不會負責。
          </v-card-text>

          <v-btn color="green-darken-1" variant="text" @click="closedialog">
            確定
          </v-btn>
        </v-card>
      </v-dialog>
    </div> -->
    <audio muted controls ref="audio" class="aud">
      <!-- <source :src="mp3url" /> -->
    </audio>
  </v-app>
</template>

<script>
import WebStorageCache from "web-storage-cache";
export default {
  name: "App",
  data() {
    return {
      mp3url: "",
      isplaymusic: false,
      isactivemusic: false,
      //dialog: false,
      playmusicstatus: "N",
    };
  },
  methods: {
    audioplayfn() {
      this.$refs.audio.load();
      let playPromise = this.$refs.audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.$refs.audio.play();
          })
          .catch(() => {});
      }
    },
    playmusic(p_isplay) {
      if (p_isplay && p_isplay == "Y") {
        try {
          this.audioplayfn(); //this.$refs.audio.play();
          this.isplaymusic = true;
        } catch (e) {}
      } else {
        try {
          this.$refs.audio.pause();
          this.isplaymusic = false;
        } catch (e) {}
      }
    },
    audioplay() {
      this.$refs.audio.src = "https://www.newgame168.com/music/samplemusic.mp3"; // l_music;
      //this.$refs.audio.play();
    },
    closedialog() {
      //this.dialog = false;
      this.isactivemusic = true;
      try {
        this.audioplayfn(); //this.$refs.audio.play();
        this.isplaymusic = true;
      } catch (e) {}
    },
  },
  computed: {
    // currentProperties: function () {
    //   return { playmusicstatus: this.isplaymusic ? "Y" : "N" };
    // },
  },
  mounted() {
    document.title = this.$companyname + "娛樂";

    var cachecheck = new WebStorageCache({
      storage: "localStorage",
      exp: Infinity,
    });

    cachecheck.set("isplaymusic", "N");
    this.isplaymusic = false;

    //this.dialog = true;
    cachecheck.set("isopendialog", "Y");
    this.audioplay();
    window.addEventListener("pageshow", () => {
      if (this.isactivemusic) {
        try {
          if (this.isplaymusic) {
            this.audioplayfn(); //this.$refs.audio.play();
            //this.isplaymusic = true;
          }
        } catch (e) {}
      }
    });

    window.addEventListener("pagehide", () => {
      if (this.isactivemusic) {
        try {
          this.$refs.audio.pause();
          this.isplaymusic = false;
        } catch (e) {}
      }
    });

    window.addEventListener("focus", () => {
      // console.log("focus");
      if (this.isactivemusic) {
        try {
          if (this.isplaymusic) {
            this.audioplayfn(); //this.$refs.audio.play();

            //this.isplaymusic = true;
          }
        } catch (e) {}
      }
    });

    window.addEventListener("blur", () => {
      // console.log("blur");
      if (this.isactivemusic) {
        try {
          this.$refs.audio.pause();
          //this.isplaymusic = false;
        } catch (e) {}
      }
    });

    // document.addEventListener("visibilitychange", function () {
    //   if (document.visibilityState === "visible") {
    //     if (this.isplaymusic) {
    //       try {
    //         this.$refs.audio.play();
    //       } catch (e) {}
    //     }
    //   } else {
    //     if (this.isplaymusic) {
    //       try {
    //         this.$refs.audio.pause();
    //       } catch (e) {}
    //     }
    //   }
    // });
  },
  created() {},
  watch: {
    isplaymusic(val) {
      var cachecheck = new WebStorageCache({
        storage: "localStorage",
        exp: Infinity,
      });

      if (val != undefined) {
        if (val) {
          try {
            this.playmusicstatus = "Y";
            cachecheck.set("isplaymusic", "Y");
          } catch (e) {}
        } else {
          try {
            this.playmusicstatus = "N";
            cachecheck.set("isplaymusic", "N");
          } catch (e) {}
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
.aud {
  display: none;
}
</style>
