import _axiosInstance from "../../plugins/axios";

// api
export const getSysMmenuList = () => {
  return _axiosInstance.get(`/api/sys/menu/list`);
};

export const getSysMBottommenuList = () => {
  return _axiosInstance.get(`/api/sys/bottommenu/list`);
};

export const getBarnnerList = (tag) => {
  return _axiosInstance.get(`api/sys/barnner/list/${tag}`);
};

export const userRegister = (data) => {
  return _axiosInstance.post(`api/user/register`, data);
};

export const userchangePwd = (data) => {
  return _axiosInstance.post(`api/user/changePwd`, data);
};

export const userchangePinCode = (data) => {
  return _axiosInstance.post(`api/user/changePinCode`, data);
};

export const userApplyDeposit = (data) => {
  return _axiosInstance.post(`api/user/applydeposit`, data);
};

export const useruploadfile = (data, config) => {
  return _axiosInstance.post(`api/user/uploadfile`, data, config);
};

export const userApplyWithDraw = (data) => {
  return _axiosInstance.post(`api/user/applywithdraw`, data);
};

//old version, not used
// export const userApplyRebate = (data) => {
//   return _axiosInstance.post(`api/user/applyrebate`, data);
// };

// export const temppatch = (data) => {
//   return _axiosInstance.post(`api/user/patchgoods`, data);
// };

export const cardTypeList = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/sys/setting/CardTypeList`, { params });
};

export const userDataList = (type, data) => {
  //console.log(data);
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/data/list/${type}`, { params });
};

export const userBalanceList = (req) => {
  return _axiosInstance.post(`api/user/balance/list`, req);
};

export const userConsumeList = (req) => {
  return _axiosInstance.post(`api/user/consume/list`, req);
};

export const userCreateData = (type, data) => {
  return _axiosInstance.post(`api/user/data/add/${type}`, data);
};

export const userRebateList = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/rebate/list`, { params });
};

export const userRebateAsyncList = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
    PageNumber: data ? data.PageNumber : 1,
    PageSize: data ? data.PageSize : 10,
  };
  return _axiosInstance.get(`api/user/rebate/asynclist`, {
    params,
  });
};

export const userCheckAllCredit = () => {
  return _axiosInstance.get(`api/user/checkallcredit`);
};

export const userRebateApply = (data) => {
  return _axiosInstance.post(`api/user/rebate/apply`, data);
};

export const userLogin = (data) => {
  return _axiosInstance.post(`api/user/login`, data);
};


export const userRecaptcha = () => {
  return _axiosInstance.get(`api/user/recaptcha`);
};


export const storeGoodsList = (data) => {
  return _axiosInstance.get(`api/store/goods/list/${data.cate_id}`);
};

export const allGamesList = () => {
  return _axiosInstance.get(`api/store/goods/list/allactive`);
};

export const cateGoodsList = (data) => {
  return _axiosInstance.get(`api/cate/goods/list/${data.cate_id}`);
};

export const getArticle = (data) => {
  return _axiosInstance.get(`api/sys/article/info/${data.id}`);
};

export const getArticleList = (data) => {
  return _axiosInstance.get(`api/sys/article/list/${data.title}`);
};

// export const userInfo = async () => {
//   return await _axiosInstance.get(`api/user/info`);
// };
export const userInfo = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/info`, { params });
};

export const userGetGiveInfo = () => {
  return _axiosInstance.get(`api/user/giveBalance`);
};

export const launchgame = (data) => {
  const params = {
    parm3: data.parm3,
    parm4: data.parm4,
    parm5: data.parm5,
    id: data.id,
  };
  return _axiosInstance.get(`api/user/launchgame`, { params });
};

export const transferallcredittobalance = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/transferallcredittobalance`, { params });
};

export const checkbalancewithcache = (data) => {
  const params = {
    iswithdraw: data.iswithdraw,
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/checkbalancewithcache`, { params });
};

export const transferbalancetogamecredit = (data) => {
  const params = {
    parm3: data.parm3,
    parm4: data.parm4,
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/transferbalancetogamecredit`, { params });
};

export const userLogout = () => {
  return _axiosInstance.post(`/api/user/logout`);
};

export const getLastConsue = (data) => {
  const params = {
    temptoken: data ? data.temptoken : "",
  };
  return _axiosInstance.get(`api/user/getLastConsue`, { params });
};

export const asyncHistory = (data) => {
  return _axiosInstance.post(`api/user/asyncHistory`, data);
};

export const asyncHistoryWithInterval = (data) => {
  return _axiosInstance.post(`api/user/asyncHistoryWithInterval`, data);
};
